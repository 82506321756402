<template>
    <div>
        <b-card title="Gestion potentiel france TV">
            <b-form-group id="postCodes" label="Listes des codes postaux : " label-for="postCodes">
                <b-form-input v-model="postCodes" id="feedback-postCodes" placeholder="77540,77120,77131"></b-form-input>
            </b-form-group>

            <b-form-group id="selectCriteres" label="Listes des critères : " label-for="selectCriteres">
                <b-form-select id="selectCriteres" v-model="selectCriteria" :options="getCriteria"></b-form-select>
            </b-form-group>

            <b-form-group id="format" label="Listes des formats : " label-for="format">
                <b-form-select id="format" v-model="selectedFormat" :options="formats"></b-form-select>
            </b-form-group>

            <b-form-group id="startDate" label="sélectionner la date de début : " label-for="startDate">
                <b-form-datepicker v-model="startDate" :date-disabled-fn="disabledStartDate" placeholder="Choisir une date"></b-form-datepicker>
            </b-form-group>
            <b-form-group v-if="!stateStartDate">
                <b-spinner variant="danger" type="grow" label="Spinning"></b-spinner>
                <span style="color: #fb4d4d; position: relative; top: -5px; left: 10px">La date de début doit être inférieure ou égale à la date de fin.</span>
            </b-form-group>

            <b-form-group id="endDate" label="sélectionner la date de fin (inclus) : " label-for="endDate">
                <b-form-datepicker v-model="endDate" :date-disabled-fn="disabledEndDate" placeholder="Choisir une date"></b-form-datepicker>
            </b-form-group>
            <b-form-group v-if="!stateEndDate">
                <b-spinner variant="danger" type="grow" label="Spinning"></b-spinner>
                <span style="color: #fb4d4d; position: relative; top: -5px; left: 10px">La date de début et date de fin doivent être dans la même année.</span>
            </b-form-group>

            <b-row class="mt-2 mr-0 justify-content-end">
                <b-button variant="success" @click="getPotential()" :disabled="!postCodes || !startDate || !endDate || !stateEndDate || !stateStartDate"> Valider </b-button>
            </b-row>

            <b-row class="ml-0" v-if="getAvailableImpressions && getAvailableImpressions.goodZip.length && !spinner">
                <strong>
                    Le nombre d'impressions possible pour ces codes postaux sur {{ getNbDay }} jour(s) est : {{ getAvailableImpressions.goodZip[0].impressionsAvailable }} <br /><br />
                    
                    À raison de {{ Math.round(getAvailableImpressions.goodZip[0].impressionsAvailable / getNbDay) }} par jour.
                </strong> </b-row
            ><br />
            <b-row class="ml-0" v-if="getAvailableImpressions && getAvailableImpressions.goodZip.length && !spinner">
                <div v-if="getAvailableImpressions.badZip.length">
                    <strong
                        >Les codes postaux suivants sont potentiellement pas pris en compte :
                        <div :key="zip" v-for="zip in getAvailableImpressions[1]">{{ zip }} <br /></div
                    ></strong>
                </div>
            </b-row>
            <b-row style="display: flex; justify-content: center" v-if="spinner">
                <b-spinner style="width: 4rem; height: 4rem; color: #7336f0" label="Large Spinner"></b-spinner>
            </b-row>
        </b-card>

        <b-card title="Exporter potentiels par marque">
            <b-form-group id="selectCriteresByBrand" label="Listes des critères : " label-for="selectCriteresByBrand">
                <b-form-select id="selectCriteresByBrand" v-model="selectCriteriaByBrand" :options="getCriteria"></b-form-select>
            </b-form-group>

            <b-form-group id="selectBrand" label="Listes des marques : " label-for="selectBrand">
                <b-form-select id="selectBrand" v-model="selectBrand" :options="brands"></b-form-select>
            </b-form-group>

            <b-form-group id="format" label="Listes des formats : " label-for="format">
                <b-form-select id="format" v-model="selectedFormatByBrand" :options="formats"></b-form-select>
            </b-form-group>

            <b-row style="display: flex; justify-content: center" v-if="spinnerPotentialByBrand">
                <b-spinner style="width: 4rem; height: 4rem; color: #7336f0" label="Large Spinner"></b-spinner>
            </b-row>

            <b-row class="mt-2 mr-0 justify-content-end">
                <b-button variant="success" @click="getPotentialByBrand()" :disabled="!selectBrand || !selectCriteriaByBrand"> Envoyer par mail </b-button>
            </b-row>

            <!--    <b-row class="ml-0" v-if="getAvailableImpressions.length && !spinner">-->
            <!--      <strong> Le nombre d'impressions possible pour ces codes postaux sur {{ getNbDay }} jour(s) est : {{ getAvailableImpressions[0].impressionsAvailable }} <br><br>-->
            <!--        À raison de {{ Math.round(getAvailableImpressions[0].impressionsAvailable / getNbDay) }} par jour.-->
            <!--      </strong>-->
            <!--    </b-row><br>-->
            <!--    <b-row class="ml-0" v-if="getAvailableImpressions.length && !spinner">-->
            <!--      <div v-if="getAvailableImpressions[1].length">-->
            <!--        <strong>Les codes postaux suivants sont invalides  :-->
            <!--          <div-->
            <!--            :key="zip"-->
            <!--            v-for="zip in getAvailableImpressions[1]">-->
            <!--            {{ zip }} <br>-->
            <!--          </div></strong>-->
            <!--      </div>-->
            <!--    </b-row>-->
            <!--    <b-row style="display: flex; justify-content:center" v-if="spinner">-->
            <!--      <b-spinner style="width: 4rem; height: 4rem; color: #7336f0;" label="Large Spinner"></b-spinner>-->
            <!--    </b-row>-->
        </b-card>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        data() {
            return {
                selectCriteria: null,
                selectCriteriaByBrand: null,
                selectBrand: 'citroen',
                formats: ['30', '20', '15'],
                selectedFormat: '0',
                selectedFormatByBrand:'0',
                brands: [],
                postCodes: '',
                startDate: '',
                endDate: '',
                spinner: false,
                spinnerPotentialByBrand: false,
            };
        },
        computed: {
            ...mapGetters({
                getCriteria: 'app/getCriteria',
                getAvailableImpressions: 'app/getAvailableImpresssions',
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            stateEndDate() {
                if (!this.startDate && !this.endDate) return true;
                return new Date(this.startDate).getFullYear() === new Date(this.endDate).getFullYear();
            },
            stateStartDate() {
                if (this.startDate && this.endDate) return this.startDate <= this.endDate;
                return true;
            },
            getNbDay() {
                let diff = new Date(this.endDate) - new Date(this.startDate);
                const nbDay = diff / (1000 * 3600 * 24) + 1;
                return nbDay
            },
        },
        watch: {
            startDate(newStartDate) {
                this.endDate = newStartDate;
            },
            endDate() {
                this.showAvailableImpressions = false;
            },
        },
        async mounted() {
            await this.getPotentialCriteria();
            await this.fetchBrands();
        },
        methods: {
            ...mapActions('app', ['getPotentialCriteria', 'getAvailablePotential', 'getFullPotentialByBrand', 'getBrands']),

            async getPotential() {
                this.spinner = true;
                const truc = await this.getAvailablePotential({ id: this.selectCriteria, format: this.selectedFormat, postCodes: this.postCodes, startDate: this.startDate, endDate: this.endDate, duration: this.selectedFormat });
                this.spinner = false;
            },
            async getPotentialByBrand() {
                this.spinnerPotentialByBrand = true;

                try {
                    this.getFullPotentialByBrand({ id: this.selectCriteriaByBrand, brand: this.selectBrand, email: localStorage.getItem('userEmail'), durationByBrand: this.selectedFormatByBrand });

                    this.$toast(this.successToast('Vous recevrez le fichier CSV sur votre boîte email dans quelques minutes.'));
                } catch (e) {
                    console.log(e);
                    this.$toast(this.errorToast('Erreur lors du téléchargement'));
                }
                this.spinnerPotentialByBrand = false;
            },
            disabledStartDate(ymd, date) {
                const now = new Date();
                let day = date.setDate(date.getDate() + 1);
                return day <= now;
            },
            disabledEndDate(ymd, date) {
                const now = new Date();
                const startDate = new Date(this.startDate);
                let day = date.setDate(date.getDate() + 1);
                return day <= now || day <= startDate;
            },
            fetchBrands() {
                return this.getBrands().then((res) => {
                    const brands = res.data.map((b) => b.name);
                    this.$set(this, 'brands', brands);
                });
            },
        },
    };
</script>
